<template>
    <component
        :is="as"
        :id="id"
        :name="name"
        :href="href"
        :disabled="disabled || isLoading"
        :download="download"
        :type="type"
        :value="value"
        :target="target"
        :class="cn(buttonVariants({color, iconDirection, padding, shape}), $attrs.class ?? '')"
        @click="$emit('click')">
        <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center gap-2 rounded-full bg-primary">
            <div
                v-for="index in 3"
                :key="index"
                class="size-4 animate-pulse rounded-full bg-white"
                :style="{animationDelay: `${index * 200}ms`}" />
        </div>
        <div v-if="isCta || icon" :class="cn(iconVariants({color}))">
            <IconArrowRight v-if="isCta" class="size-4" />
            <component :is="icon" v-if="icon" class="size-4" />
        </div>
        <div class="text-base font-medium shrink-0 px-3 text-center">
            <slot />
        </div>
    </component>
</template>

<script setup lang="ts">
import {type Component} from 'vue';
import {IconArrowRight} from '@/assets/icons';
import {cn} from '@/utils/cn';
import {type VariantProps, cva} from 'class-variance-authority';

defineOptions({
    name: 'SGButton',
    inheritAttrs: false,
    customOptions: {},
});

defineEmits(['click']);

const buttonVariants = cva('duration-300 relative inline-flex items-center justify-center group/sg-button border', {
    variants: {
        color: {
            purple: 'bg-primary hover:bg-white text-white hover:text-primary border-primary/0 hover:border-primary',
            white: 'bg-white text-primary hover:bg-primary hover:text-white border-primary hover:border-white',
            'white-two': 'bg-white text-primary hover:bg-primary hover:text-white border-primary hover:border-white',
        },
        shape: {
            square: 'rounded-md',
            round: 'rounded-full',
        },
        iconDirection: {
            left: 'flex-row',
            right: 'flex-row-reverse',
        },
        padding: {
            md: 'p-2',
            lg: 'p-3.5',
        },
    },
});

const iconVariants = cva('p-2 rounded-full duration-300', {
    variants: {
        color: {
            purple: 'bg-white text-primary group-hover/sg-button:bg-primary group-hover/sg-button:text-white',
            white: 'bg-primary text-white group-hover/sg-button:bg-white group-hover/sg-button:text-primary',
            'white-two': 'text-primary group-hover/sg-button:text-white',
        },
    },
});

type ButtonPrimaryProps = {
    href?: string;
    disabled?: boolean;
    download?: string;
    as?: string;
    id?: string;
    name?: string;
    value?: string;
    isLoading?: boolean;
    type?: string;
    target?: string;
    icon?: Component;
    iconDirection?: VariantProps<typeof buttonVariants>['iconDirection'];
    padding?: VariantProps<typeof buttonVariants>['padding'];
    isCta?: boolean;
    color?: VariantProps<typeof buttonVariants>['color'];
    shape?: VariantProps<typeof buttonVariants>['shape'];
};

withDefaults(defineProps<ButtonPrimaryProps>(), {
    href: undefined,
    download: undefined,
    icon: undefined,
    type: undefined,
    id: undefined,
    name: undefined,
    value: undefined,
    target: undefined,
    iconDirection: 'right',
    padding: 'md',
    as: 'button',
    color: 'purple',
    shape: 'round',
});
</script>
